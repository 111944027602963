<template>
  <!-- Add new email ---------------------------------------------------------------------------->
  <b-card id="add-domain-email">
    <template #header>
      Create New Email Address
    </template>
    <div class="add-domain-email-form-group">
      <b-form class="w-100">
        <b-row>
          <b-col cols="4">
            <b-form-group label="Email Address" label-for="username">
              <b-input-group append="@">
                <b-form-input
                  id="username"
                  v-model="username"
                  :disabled="loading"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="4" class="domain-name px-0 flex-grow-1">
            {{ domain.domain_name }}
          </b-col>
          <b-col cols="4">
            <validation-provider v-slot="{ validate, errors }" name="password" rules="required|domainEmailPasswordValidator">
              <b-form-group label="Password" label-for="password">
                <b-input-group>
                  <b-form-input
                    id="password"
                    v-model="password"
                    :disabled="loading"
                    :type="showPassword ? 'text' : 'password'"
                    @input="validatePassword(validate)"
                  />
                  <b-input-group-append class="password-visibility">
                    <fa-icon
                      :icon="showPassword ? 'eye' : 'eye-slash'"
                      style="cursor: pointer;"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                  <b-card v-if="password.length && errors.length" class="small popdown">
                    {{ errors.length > 0 ? errors[0] : '' }}
                  </b-card>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <div class="item-actions ml-3">
        <template v-if="!loading">
          <b-btn
            variant="primary"
            :disabled="!isPasswordValid || username.length < 1"
            @click="save"
          >
            Save
          </b-btn>
          <b-btn
            variant="outline-danger"
            @click="hide"
          >
            Cancel
          </b-btn>
        </template>
        <ct-centered-spinner v-else />
      </div>
    </div>
  </b-card>
</template>

<script>
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { makeToastMixin } from '@/mixins/makeToastMixin.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DomainEmails',
  components: {
    CtCenteredSpinner,
  },
  mixins: [makeToastMixin],
  data() {
    return {
      loading: false,
      username: '',
      password: '',
      showPassword: false,
      errors: [],
      isPasswordValid: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
  },
  methods: {
    ...mapActions('domainEmails', [
      'createDomainEmail',
    ]),
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    hide() {
      this.$emit('addDomainEmailClose')
    },
    async save() {
      this.loading = true

      const res = await this.createDomainEmail({
        domainId: this.domain.id,
        emailUsername: this.username,
        password: this.password,
      })

      if (res?.data?.status === 200) {
        this.$emit('addDomainEmailClose', true)
      } else {
        this.$emit('addDomainEmailClose', false)
      }
      this.loading = false
    },
    async validatePassword(validate) {
      this.isPasswordValid = (await validate()).valid
    },
  },
}
</script>

<style lang="scss" scoped>

#add-domain-email {
  ::v-deep {
    .add-domain-email-form-group {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-end;
      .row {
        margin-bottom: 0;
      }
      .input-group-text {
        font-weight: 900;
        font-size: 1.6rem;
        height: 46px;
        padding-top: 0;
      }
      .password-visibility {
        right: 1rem;
        position: absolute;
        top: 0.9rem;
        z-index: 1000;
      }
      .form-group, input {
        margin-bottom: 0;
      }
      .domain-name {
        font-weight: 900;
        display: flex;
        align-items: flex-end;
        padding-left: .2rem;
        padding-bottom: .7rem;
      }
      #password {
        border-radius: 4px !important;
      }
      .item-actions {
        min-width: 162px;
        button {
          font-weight: 900;
          margin: 2px 4px;
          padding-left: .8em;
          padding-right: .8em;
        }
      }
      .popdown {
        position: absolute;
        z-index: 3000;
        top: 50px;
        opacity: .9;
      }
    }
  }
}

</style>
