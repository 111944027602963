<template>
  <domain-record-list
    id="domain-txt-records"
    title="TXT Records"
    subtitle="(TXT)"
    type="TXT"
    :records="txtRecords"
  />
</template>

<script>
import DomainRecordList from './DomainRecordList'
import { mapGetters } from 'vuex'

export default {
  name: 'DomainTxtRecords',
  components: {
    DomainRecordList,
  },
  computed: {
    ...mapGetters('domains', [
      'txtRecords',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>
