<template>
  <domain-record-list
    id="domain-external-hosts"
    title="External Hosts"
    subtitle="(A, AAAA)"
    type="A"
    :records="externalHosts"
  />
</template>

<script>
import DomainRecordList from './DomainRecordList'
import { mapGetters } from 'vuex'

export default {
  name: 'DomainExternalHosts',
  components: {
    DomainRecordList,
  },
  props: {},
  computed: {
    ...mapGetters('domains', [
      'externalHosts',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>
