<template>
  <div v-if="loading">
    <ct-centered-spinner />
  </div>
  <div v-else>
    <b-alert
      :variant="getServiceActionVariant()"
      :class="`mb-4 ${getServiceActionAlertClass()}`"
      show
    >
      <b-icon
        :icon="getAlertIcon()"
        :class="`mr-3 text-${getServiceActionVariant()} circle-icon`"
      />
      <domain-service-removal-timer
        :action="getNextServiceAction()"
        :service-name="service.product.name"
        :delete-date-string="service.delete_date"
        :action-date-string="getNextServiceActionDate()"
        :action-time-frame="getNextServiceActionTimeFrame()"
      />
    </b-alert>
  </div>
</template>

<script>

import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { mapActions } from 'vuex'
import DomainServiceRemovalTimer from '@/components/Domains/DomainServiceRemovalTimer.vue'

export default {
  name: "DomainServicePendingRemoval",
  components: { DomainServiceRemovalTimer, CtCenteredSpinner },
  props: {
    service:  {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      currentTime: null,
      loading: false,
      inProcess: false,
      showReactivationConfirmWindow: false,
    }
  },
  methods: {
    ...mapActions('products', ['loadProductByParams']),
    getNextServiceAction() {
      const serviceAction = {
        'new': 'suspended',
        'suspended': 'deleted permanently',
      }

      return serviceAction[this.service.cancellation_status] || null
    },
    getNextServiceActionDate() {
      const actionDate = {
        'new': this.service.suspend_date,
        'suspended': this.service.delete_date,
      }

      return actionDate[this.service.cancellation_status] || null
    },
    getNextServiceActionTimeFrame() {
      const actionTimeFrame = {
        'new': 15,
        'suspended': 45,
      }

      return actionTimeFrame[this.service.cancellation_status] || null
    },
    getServiceActionVariant() {
      const actionVariant = {
        'new': 'warning',
        'suspended': 'danger',
      }

      return actionVariant[this.service.cancellation_status] || null
    },
    getServiceActionAlertClass(){
      const actionAlertClass = {
        'new': 'expire-notification-warning',
        'suspended': 'expire-notification-danger',
      }

      return actionAlertClass[this.service.cancellation_status] || null
    },
    getAlertIcon() {
      const variant = this.getServiceActionVariant()
      return variant === 'danger' ? 'x-circle-fill' : 'exclamation-triangle-fill'
    },
  },
}
</script>

<style lang="scss">
  @mixin flexbox-border($color: orange, $background: #ffa5001c) {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $background;
    border-color: $color;
    color: black;
  }

  .circle-icon {
    width: 1.5em; height: 1.5em;
  }

  .expire-notification-warning {
    @include flexbox-border();
  }

  .expire-notification-danger {
    @include flexbox-border($color: red, $background: #f8d7da78);
  }

</style>
