<template>
  <b-modal
    id="email-delete-modal"
    ref="email-delete-modal"
    title="Delete Email Account?"
    centered
    hide-header-close
    size="md"
  >
    <div v-html="modalText" />
    <template v-slot:modal-footer>
      <b-button variant="default" @click="$emit('hide')">
        Cancel
      </b-button>
      <b-button variant="primary" @click="$emit('delete')">
        Delete Email Account
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
  name: 'EmailDeleteModal',
  data() {
    return {}
  },
  computed: {
    modalText() {
      const text = '<p>You are about to delete this email account.</p><p><strong>This cannot be undone.</strong></p>'
      return DOMPurify.sanitize(text, {
        ALLOWED_TAGS: ['p', 'strong'],
      })
    },
  },
}
</script>
