var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nameserver" },
    [
      _c(
        "b-form",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "7" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: { label: "Nameserver", "label-for": "nameserver" },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "nameserver", disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "value", $$v)
                          },
                          expression: "record.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }