<template>
  <div class="external-host">
    <b-form>
      <b-row>
        <b-col cols="2">
          <b-form-group label="Host" label-for="name">
            <b-form-input
              id="name"
              v-model="record.name"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2" class="domain-name px-0 flex-grow-1">
          {{ domain.domain_name }}
        </b-col>
        <b-col cols="2">
          <b-form-group label="Type" label-for="type">
            <b-form-select
              id="type"
              v-model="record.type"
              :disabled="disabled"
              :options="typeOptions"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Points To" label-for="data">
            <b-form-input
              id="data"
              v-model="record.data"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="TTL" label-for="ttl">
            <b-form-select
              id="ttl"
              v-model="record.ttl"
              :options="ttlOptions"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TTL_OPTIONS } from '@/common/modules/domains.js'

export default {
  name: 'ExternalHost',
  components: {},
  props: {
    record: {
      type: Object,
      required: false,
    },
    disable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      disabled: true,
      typeOptions: [
        { value: null, text: 'Select One', disabled: true },
        'A',
        'AAAA',
      ],
      ttlOptions: TTL_OPTIONS,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
  },
  watch: {
    disable(value) {
      this.disabled = value
    },
  },
  created() {
    this.disabled = !this.record.temp ? this.disable : false
  },
  methods: {
    input() {
      this.$emit('input')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
