var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.action === "deleted permanently"
    ? _c("div", [
        _c("strong", [
          _vm._v(
            "\n    Your " +
              _vm._s(_vm.serviceName) +
              " will be " +
              _vm._s(_vm.action) +
              " " +
              _vm._s(_vm.untilTheEndDate) +
              "\n  "
          ),
        ]),
        _c("p", { staticClass: "alert-description" }, [
          _vm._v(
            "\n    There's still time to save your " +
              _vm._s(_vm.serviceName) +
              " data. For assistance and to go over your options for saving or transferring data, please give us a call at "
          ),
          _c("span", {
            directives: [
              {
                name: "phone-number",
                rawName: "v-phone-number",
                value: _vm.phone(),
                expression: "phone()",
              },
            ],
          }),
          _vm._v(" (M-F 8AM-4:30PM PST).\n  "),
        ]),
      ])
    : _vm.action === "suspended"
    ? _c("div", [
        _c("strong", [
          _vm._v(
            "\n    " +
              _vm._s(_vm.serviceName) +
              " Cancellation - Data Removal\n  "
          ),
        ]),
        _c("p", { staticClass: "alert-description" }, [
          _vm._v(
            "\n    Your " +
              _vm._s(_vm.serviceName) +
              " data will no longer be accessible by our Web Support team after " +
              _vm._s(_vm.formatDateString(_vm.deleteDateString)) +
              ". If you are considering revisiting this service or need a copy of your data, please give us a call at "
          ),
          _c("span", {
            directives: [
              {
                name: "phone-number",
                rawName: "v-phone-number",
                value: _vm.phone(),
                expression: "phone()",
              },
            ],
          }),
          _vm._v(" (M-F 8AM-4:30PM PST).\n  "),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }