var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: _vm.modalId,
      attrs: {
        id: _vm.modalId,
        title: "Domain Addon Special Offer",
        "no-close-on-backdrop": "",
        centered: "",
        "hide-footer": "",
        size: "lg",
      },
    },
    [
      _c(
        "div",
        { staticClass: "additional-offer-container" },
        [
          _c("i", [
            _vm._v(
              "\n      Add " +
                _vm._s(_vm.getAddonProductsString()) +
                " to unlock 20% off all "
            ),
            _c("strong", [_vm._v("Online Presence")]),
            _vm._v(" services!\n    "),
          ]),
          _c("br"),
          !_vm.isDomainSsl &&
          !!_vm.domainAddonOtherServiceProduct &&
          _vm.showSslOffer
            ? _c(
                "div",
                [
                  _c(
                    "b-radio-group",
                    {
                      staticClass: "mb-3",
                      model: {
                        value: _vm.packageSelected,
                        callback: function ($$v) {
                          _vm.packageSelected = $$v
                        },
                        expression: "packageSelected",
                      },
                    },
                    [
                      _c(
                        "b-radio",
                        {
                          attrs: { value: true },
                          on: {
                            change: function ($event) {
                              return _vm.activateAll()
                            },
                          },
                        },
                        [_vm._v("\n          Yes please!\n        ")]
                      ),
                      _c(
                        "b-radio",
                        {
                          attrs: { value: false },
                          on: {
                            change: function ($event) {
                              return _vm.deactivateOther()
                            },
                          },
                        },
                        [_vm._v("\n          No thanks.\n        ")]
                      ),
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      "\n        Or, you can select any additional service you would like to add.\n      "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              !!_vm.domainAddonOtherServiceProduct
                ? _c(
                    "b-checkbox",
                    {
                      model: {
                        value: _vm.otherServiceChecked,
                        callback: function ($$v) {
                          _vm.otherServiceChecked = $$v
                        },
                        expression: "otherServiceChecked",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.domainAddonOtherServiceProduct.name) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isDomainSsl && _vm.showSslOffer
                ? _c(
                    "b-checkbox",
                    {
                      model: {
                        value: _vm.sslServiceChecked,
                        callback: function ($$v) {
                          _vm.sslServiceChecked = $$v
                        },
                        expression: "sslServiceChecked",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.domainAddonSslServiceProduct.name) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.sslServiceChecked
                ? _c(
                    "b-alert",
                    {
                      staticClass: "mb-3",
                      attrs: { show: "", variant: "danger" },
                    },
                    [
                      _c("p", [
                        _c("strong", { staticClass: "mb-1" }, [
                          _vm._v(
                            "WARNING: " +
                              _vm._s(_vm.domainAddonSslServiceProduct.name) +
                              " Service Disabled!"
                          ),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v("\n          SSL service is "),
                        _c("strong", [_vm._v("highly")]),
                        _vm._v(" recommended for "),
                        _c("strong", [_vm._v("Business Website Hosting")]),
                        _vm._v(" or "),
                        _c("strong", [_vm._v("Business Email")]),
                        _vm._v(
                          " service.\n          Without it, your website will be insecure and your user's browsers will mark the website as untrustworthy. Additionally, your emails won't be secure. Please be aware of this before continuing, you can add SSL later if you choose not to right now.\n        "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          !_vm.loadingCheckout
            ? _c(
                "b-btn",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addAdditionalProductsToCartAndContinue()
                    },
                  },
                },
                [_vm._v("\n      Continue to Checkout\n    ")]
              )
            : _c("ct-centered-spinner", {
                staticClass: "loading-spinner mb-5",
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }