var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("domain-record-list", {
    attrs: {
      id: "domain-external-hosts",
      title: "External Hosts",
      subtitle: "(A, AAAA)",
      type: "A",
      records: _vm.externalHosts,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }