var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("div", [_c("ct-centered-spinner")], 1)
    : _c(
        "div",
        [
          _c(
            "b-alert",
            {
              class: `mb-4 ${_vm.getServiceActionAlertClass()}`,
              attrs: { variant: _vm.getServiceActionVariant(), show: "" },
            },
            [
              _c("b-icon", {
                class: `mr-3 text-${_vm.getServiceActionVariant()} circle-icon`,
                attrs: { icon: _vm.getAlertIcon() },
              }),
              _c("domain-service-removal-timer", {
                attrs: {
                  action: _vm.getNextServiceAction(),
                  "service-name": _vm.service.product.name,
                  "delete-date-string": _vm.service.delete_date,
                  "action-date-string": _vm.getNextServiceActionDate(),
                  "action-time-frame": _vm.getNextServiceActionTimeFrame(),
                },
              }),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }