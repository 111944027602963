<template>
  <div>
    <b-form-select
      v-model="valueLocal"
      :options="getOptions"
      :disabled="disabled"
    />
  </div>
</template>

<script>

import allCountries from '@/lib/allCountries'

export default {
  name: 'CountryCodeSelect',
  components: {},
  props: {
    value: null,
    disabled: Boolean,
  },
  computed: {
    valueLocal: {
      get: function() {
        return this.value
      },
      set: function(value) {
        this.$emit('change', value)
      },
    },
    getOptions() {
      return [...new Set(allCountries.map(country => country.dialCode))]
        .sort((dialCodeA, dialCodeB) => {
          if (dialCodeA < dialCodeB) {
            return -1
          } else if (dialCodeA > dialCodeB) {
            return 1
          }

          return 0
        })
        .map(dialCode => ({
          text: `+${dialCode}`,
          value: dialCode,
        }))
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
