var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "txt-record" },
    [
      _c(
        "b-form",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Host", "label-for": "name" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "name", disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "name", $$v)
                          },
                          expression: "record.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Host", "label-for": "type" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "type", disabled: true },
                        model: {
                          value: _vm.record.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "type", $$v)
                          },
                          expression: "record.type",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Value", "label-for": "data" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "data", disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.data,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "data", $$v)
                          },
                          expression: "record.data",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "TTL", "label-for": "ttl" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "ttl",
                          options: _vm.ttlOptions,
                          disabled: _vm.disabled,
                        },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.ttl,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "ttl", $$v)
                          },
                          expression: "record.ttl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }