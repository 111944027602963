<template>
  <div class="mx-record">
    <b-form>
      <b-row>
        <b-col cols="2">
          <b-form-group label="Host" label-for="name">
            <b-form-input
              id="name"
              v-model="record.name"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-form-group label="Type" label-for="type">
            <b-form-input
              id="type"
              v-model="record.type"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-group label="Points To" label-for="data">
            <b-form-input
              id="data"
              v-model="record.data"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="TTL" label-for="ttl">
            <b-form-select
              id="ttl"
              v-model="record.ttl"
              :options="ttlOptions"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Priority" label-for="priority">
            <b-form-input
              id="priority"
              v-model="record.priority"
              type="number"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { TTL_OPTIONS } from '@/common/modules/domains.js'

export default {
  name: 'Mx',
  components: {},
  props: {
    record: {
      type: Object,
      default: () => {},
      required: false,
    },
    disable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      disabled: true,
      ttlOptions: TTL_OPTIONS,
    }
  },
  watch: {
    disable(value) {
      this.disabled = value
    },
  },
  created() {
    this.disabled = !this.record.temp ? this.disable : false
  },
  methods: {
    input() {
      this.$emit('input')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
