<template>
  <div class="whois-contact-info">
    <b-form>
      <h4>Contact Information</h4>
      <b-form-group class="mt-4">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Organization" label-for="organization">
              <b-form-input
                id="organization"
                v-model="whois.org"
                :disabled="domainPrivacy"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                :value="whois.name"
                :disabled="domainPrivacy"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
      <h4>Address</h4>
      <b-form-group class="mt-4">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Address" label-for="address">
              <b-form-input
                id="address"
                :value="whois.address"
                :disabled="domainPrivacy"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="City" label-for="city">
              <b-form-input
                id="city"
                :value="whois.city"
                :disabled="domainPrivacy"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="State" label-for="state">
              <b-form-input
                id="state"
                :value="whois.state"
                :disabled="domainPrivacy"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="ZIP" label-for="zip">
              <b-form-input
                id="zip"
                :value="whois.zip"
                :disabled="domainPrivacy"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Country" label-for="country">
              <b-form-input
                id="country"
                :value="whois.country"
                :disabled="domainPrivacy"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <b-form-group label="Country Code" label-for="country-code">
              <country-code-select v-model="whois.countryCode" :disabled="domainPrivacy" />
            </b-form-group>
          </b-col>
          <b-col cols="9">
            <b-form-group label="Phone" label-for="phone">
              <b-form-input
                id="phone"
                :value="whois.phone"
                :disabled="domainPrivacy"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Email Address" label-for="email">
              <b-form-input
                id="email"
                :value="whois.email"
                :disabled="domainPrivacy"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <b-form-group>
              <b-checkbox v-model="shouldUseForAll" inline :disabled="domainPrivacy">
                Use for all contact types
              </b-checkbox>
              <!--
              This component is disabled because everything is private by default and there is
              not currently a way to change this. Once implemented, the disabled flag can be removed.
              -->
              <b-checkbox v-model="domainPrivacy" inline disabled>
                Make private
              </b-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="!domainPrivacy">
          <b-col cols="12">
            <b-button variant="primary" @click="submit()">Save</b-button>
            <b-button variant="outline-danger">Cancel</b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import CountryCodeSelect from '@/components/shared/CountryCodeDropdown.vue'

const whoisKeys = ['owner', 'admin', 'tech']

export default {
  name: 'WhoisContactInfo',
  components: {
    CountryCodeSelect,
  },
  props: {
    whoisKey: null,
  },
  data: function() {
    return {
      checkbox: [],
      whois: {},
      domainPrivacy: true,
      shouldUseForAll: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'domainWhois',
    ]),
  },
  watch: {
    // Update local component state if vuex state changes.
    domain(newVal) {
      this.domainPrivacy = newVal.domain_privacy
    },
    domainWhois(newVal) {
      this.whois = JSON.parse(JSON.stringify(newVal[this.whoisKey]))
    },
  },
  mounted: function() {
    // Use JSON parse/stringify to deep clone.
    this.whois = JSON.parse(JSON.stringify(this.domainWhois[this.whoisKey]))
    this.domainPrivacy = this.domain.domain_privacy
  },
  methods: {
    ...mapActions('domains', [
      'setWhois',
    ]),
    submit() {
      const newWhois = { ...this.domainWhois }

      if (this.shouldUseForAll) {
        whoisKeys.forEach(key => newWhois[key] = { ...newWhois[key], ...this.whois })
      } else {
        newWhois[this.whoisKey] = { ...newWhois[this.whoisKey], ...this.whois }
      }


      this.setWhois({
        id: this.domain.id,
        newWhois: newWhois,
        newPrivacy: this.domainPrivacy,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.whois-contact-info {
  max-width: 524px;
  .row,
  .form-group {
    margin-bottom: 8px;
  }
  ::v-deep .body-text label {
    font-size: 1rem !important;
  }
}
</style>

<style lang="scss">
.vue-country-select {
  .dropdown {
    min-height: 44px;
    padding: .375rem .75rem !important;
    border-color: rgb(206, 212, 218);
  }
}
</style>
