<template>
  <domain-record-list
    id="domain-ns-records"
    title="NS Records"
    subtitle="(NS)"
    type="NS"
    :records="nsRecords"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import DomainRecordList from './DomainRecordList'

export default {
  name: 'DomainNsRecords',
  components: {
    DomainRecordList,
  },
  computed: {
    ...mapGetters('domains', [
      'nsRecords',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>
