var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !!_vm.selectedDomainRecord,
          expression: "!!selectedDomainRecord",
        },
      ],
      ref: "domain-record-delete-modal",
      attrs: {
        id: "domain-record-delete-modal",
        title: "Delete Record",
        centered: "",
        "hide-header-close": "",
        size: "md",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "default", "aria-label": "cancel button" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", "aria-label": "delete button" },
                  on: { click: _vm.confirmDelete },
                },
                [_vm._v("\n      Delete\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.loading
        ? _c("ct-centered-spinner")
        : _c("div", { domProps: { innerHTML: _vm._s(_vm.modalText) } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }