var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "domain-admin-hosting-login" }, [
    _c("h4", [
      _c("span", [
        _vm._v("Domain Hosting Login"),
        _vm.subtitle
          ? _c("span", { staticClass: "subtitle" }, [
              _vm._v(" " + _vm._s(_vm.subtitle)),
            ])
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      [
        _c(
          "b-button",
          {
            attrs: { variant: "primary" },
            on: {
              click: function ($event) {
                return _vm.launchCPanel()
              },
            },
          },
          [_vm._v("\n      cPanel Login\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }