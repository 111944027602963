<template>
  <div class="glue-record">
    <b-form>
      <b-row>
        <b-col cols="2" class="align-items-start">
          <b-form-group label="Nameserver" label-for="data">
            <b-form-input
              id="data"
              v-model="record.data"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2" class="domain-name flex-grow-1 align-items-start">
          {{ domain.domain_name }}
        </b-col>
        <b-col cols="2">
          <b-form-group label-for="ipv4">
            <label for="ipv4">
              <strong>IPv4</strong>&nbsp;Addresses
            </label>
            <b-form-input
              class="glue-ip"
              v-model="record.ipv4[0]"
              :disabled="disabled"
              @input="input"
            />
            <b-form-input
              class="glue-ip"
              v-model="record.ipv4[1]"
              :disabled="disabled"
              @input="input"
            />
            <b-form-input
              class="glue-ip"
              v-model="record.ipv4[2]"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <label for="ipv6">
              <strong>IPv6</strong>&nbsp;Addresses
            </label>
            <b-form-input
              class="glue-ip"
              v-model="record.ipv6[0]"
              :disabled="true"
              @input="input"
            />
            <b-form-input
              class="glue-ip"
              v-model="record.ipv6[1]"
              :disabled="true"
              @input="input"
            />
            <b-form-input
              class="glue-ip"
              v-model="record.ipv6[2]"
              :disabled="true"
              @input="input"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GlueRecord',
  components: {},
  props: {
    record: {
      type: Object,
      default: () => {},
      required: false,
    },
    disable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      disabled: true,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ])
  },
  watch: {
    disable(value) {
      this.disabled = value
    },
  },
  created() {
    this.disabled = !this.record.temp ? this.disable : false
  },
  methods: {
    input() {
      this.$emit('input')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .domain-name {
  padding: 34px 0 0 0 !important;
}
.glue-ip {
  margin-bottom: .5rem !important;
}
</style>
