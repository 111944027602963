<template>
  <div class="ct-empty-view-component">
    <fa-icon
      v-if="icon"
      :icon="icon"
      :size="size"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CtEmptyView',
  props: {
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  computed: {},
}
</script>

<style lang="scss">
.ct-empty-view-component {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  font-size: 13px !important;
  color: #BBB !important;
  width: 100%;
  justify-content: center;
  align-items: center;
  * {
    margin-bottom: 12px;
  }
}

</style>
