<template>
  <div class="domain-swap-configuration-container">
    <ct-centered-spinner v-if="swappingDomain" class="loading-spinner mb-5">
      <p class="loading-text">
        Swapping your domain, this can take a few minutes...
      </p>
      <p>
        <em>
          Please do not navigate away or refresh the page.
        </em>
      </p>
    </ct-centered-spinner>
    <div v-else-if="domainSwapped">
      <p>
        Your domain
        <span class="font-weight-bold">
          {{ domain.domain_name }}
        </span>
        has been successfully swapped to
        <span class="font-weight-bold">
          {{ selectedDomain.toUpperCase() }}
        </span>
        .
      </p>
      <p>
        You may now exit out of this modal
      </p>
    </div>
    <choose-domain
      v-else
      :domain-admin-swap="true"
      :domain="domain"
      :bus="bus"
      :show-name-change="false"
    />

    <div
      v-if="!domainSwapped"
      class="buttons mt-3 mb-0 d-flex flex-column flex-end"
    >
      <div class="slide-viewer-button-row mt-3 mb-0">
        <primary-button
          button-text="Swap Domain"
          :disabled="swappingDomain"
          @onclick="performDomainSwap()"
        />
        <b-button
          variant="default"
          :disabled="swappingDomain"
          @click="$emit('cancel')"
        >
          Cancel
        </b-button>
      </div>
    </div>

    <div
      v-else
      class="buttons mt-3 mb-0 d-flex flex-column flex-end"
    >
      <div class="slide-viewer-button-row mt-3 mb-0">
        <primary-button
          button-text="Close"
          @onclick="$emit('hide')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'DomainSwapConfiguration',
  components: {
    ChooseDomain:      () => import('@/components/Services/Domains/ChooseDomain'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    PrimaryButton:     () => import('@/components/StagelineV2/shared/PrimaryButton'),
  },
  mixins: [makeToastMixin],
  props: {
    slide: null,
  },
  data() {
    return {
      bus: new Vue(),
      swappingDomain: false,
      domainSwapped: false,
      savingMessage: '',
      migrateWp: false,
      migrateEmail: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
    ]),
    ...mapGetters('domains', [
      'selectedDomain',
      'domain',
    ]),
    ...mapGetters('vouchers', [
      'voucherByProductCategory',
    ]),
    buttonText() {
      return this.slide?.config?.continue_button.text || 'Continue'
    },
  },
  methods: {
    ...mapActions('domains', [
      'swapDomain',
    ]),
    ...mapActions('companies', [
      'loadActiveServiceByType',
    ]),

    async performDomainSwap() {
      if (!this.selectedDomain) {
        this.errorToast('Error', 'Select a domain.')
        return
      }

      this.swappingDomain = true

      let returnResult = false

      const result = await this.swapDomain({ id: this.domain.id, sourceDomain: this.domain.domain_name, targetDomain: this.selectedDomain })

      if (result?.success) {
        this.successToast('Success', 'Domain has been swapped.')
        this.domainSwapped = true
        returnResult = true
      } else {
        this.domainSwapped = false
        returnResult = false
        this.errorToast('Error', 'Unable to swap domain.')
      }

      this.swappingDomain = false

      return returnResult
    },
  },
}
</script>
<style scoped lang="scss">
.domain-swap-configuration-container {
  .form-title {
    font-weight: bold;
  }
  .buttons {

  }
  .loading-spinner {
    height: 6rem !important;
  }
  .loading-text {
    width: max-content;
    word-wrap: break-word;
    max-width: 75vw;
  }
}
</style>
