var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: _vm.modalId,
      attrs: {
        id: _vm.modalId,
        title: "Domain Addon Service Checkout",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        centered: "",
        "hide-footer": "",
        size: "lg",
      },
    },
    [
      _c("div", { staticClass: "checkout-cart-container" }, [
        _c(
          "div",
          [
            _c("checkout-cart", {
              staticClass: "checkout-cart",
              attrs: {
                "show-tos-url": true,
                "verify-order-checkout": false,
                "verify-order-checkout-button-overrides": true,
              },
              on: {
                submit: function ($event) {
                  return _vm.$emit("setup-addon-service")
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }