var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      attrs: { id: "add-domain-record" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [_vm._v("\n    Add Record\n  ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("domain-record", {
        attrs: { record: _vm.record, type: _vm.type },
        on: {
          cancel: function ($event) {
            return _vm.$emit("cancel")
          },
          success: _vm.succeed,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }