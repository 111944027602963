var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "reactivateServiceWindow",
      attrs: {
        id: "reactivate-service-window",
        title: "Confirm Service Reactivation",
        size: "xl",
        "hide-footer": "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _vm.loaded
        ? _vm._t("body", function () {
            return [
              _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "Please select the payment method you would like to use to make your payment today, " +
                        _vm._s(_vm.dateToday) +
                        "."
                    ),
                  ]),
                  _c("payment-method-options"),
                  _c("p", [
                    _c("i", [
                      _vm._v(
                        "If you would like to use a different payment method, please add the payment method to your Payment Methods under Account Settings first."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("p", [
                _vm._v(
                  "I agree to reactivate my " +
                    _vm._s(_vm.service.product.name) +
                    " service for the domain "
                ),
                _c("b", [_vm._v(_vm._s(_vm.domain.domain_name))]),
                _vm._v(
                  " for $" +
                    _vm._s(_vm.currentProduct.price) +
                    " per " +
                    _vm._s(_vm.pricePeriod) +
                    "."
                ),
              ]),
              _vm.tosUrl
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-form-group", [
                            _c("span", { staticClass: "mt-4 mb-2" }, [
                              _vm._v(
                                "\n            By clicking 'Reactivate', I accept the "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: _vm.tosUrl, target: "_blank" },
                                },
                                [_vm._v("Terms and Conditions")]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "b-button",
                    {
                      staticClass: "reactivate-button",
                      attrs: {
                        variant: "primary",
                        disabled: !_vm.submitButtonEnabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit()
                        },
                      },
                    },
                    [_vm._v("\n      Reactivate\n    ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "b-button",
                    {
                      staticClass: "reactivate-button ml-md-1",
                      attrs: { variant: "outline-danger" },
                      on: {
                        click: function ($event) {
                          return _vm.resetModal()
                        },
                      },
                    },
                    [_vm._v("\n      Cancel\n    ")]
                  )
                : _vm._e(),
              _vm.loading
                ? _c(
                    "ct-centered-spinner",
                    { staticClass: "loading-spinner mb-lg-5" },
                    [
                      _c("p", { staticClass: "centered-spinner-text" }, [
                        _vm._v(
                          "\n        Reactivating " +
                            _vm._s(_vm.service.product["name"]) +
                            " service...\n      "
                        ),
                      ]),
                      _c("p", [
                        _c("em", [
                          _vm._v(
                            "\n          Please do not navigate away or refresh the page.\n        "
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          })
        : _c(
            "div",
            { staticClass: "d-flex flex-row justify-content-center" },
            [_c("ct-centered-spinner", { attrs: { label: "Loading..." } })],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }