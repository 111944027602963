var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "glue-record" },
    [
      _c(
        "b-form",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "align-items-start", attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Nameserver", "label-for": "data" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "data", disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.data,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "data", $$v)
                          },
                          expression: "record.data",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "domain-name flex-grow-1 align-items-start",
                  attrs: { cols: "2" },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.domain.domain_name) + "\n      "
                  ),
                ]
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-for": "ipv4" } },
                    [
                      _c("label", { attrs: { for: "ipv4" } }, [
                        _c("strong", [_vm._v("IPv4")]),
                        _vm._v(" Addresses\n          "),
                      ]),
                      _c("b-form-input", {
                        staticClass: "glue-ip",
                        attrs: { disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.ipv4[0],
                          callback: function ($$v) {
                            _vm.$set(_vm.record.ipv4, 0, $$v)
                          },
                          expression: "record.ipv4[0]",
                        },
                      }),
                      _c("b-form-input", {
                        staticClass: "glue-ip",
                        attrs: { disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.ipv4[1],
                          callback: function ($$v) {
                            _vm.$set(_vm.record.ipv4, 1, $$v)
                          },
                          expression: "record.ipv4[1]",
                        },
                      }),
                      _c("b-form-input", {
                        staticClass: "glue-ip",
                        attrs: { disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.ipv4[2],
                          callback: function ($$v) {
                            _vm.$set(_vm.record.ipv4, 2, $$v)
                          },
                          expression: "record.ipv4[2]",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("label", { attrs: { for: "ipv6" } }, [
                        _c("strong", [_vm._v("IPv6")]),
                        _vm._v(" Addresses\n          "),
                      ]),
                      _c("b-form-input", {
                        staticClass: "glue-ip",
                        attrs: { disabled: true },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.ipv6[0],
                          callback: function ($$v) {
                            _vm.$set(_vm.record.ipv6, 0, $$v)
                          },
                          expression: "record.ipv6[0]",
                        },
                      }),
                      _c("b-form-input", {
                        staticClass: "glue-ip",
                        attrs: { disabled: true },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.ipv6[1],
                          callback: function ($$v) {
                            _vm.$set(_vm.record.ipv6, 1, $$v)
                          },
                          expression: "record.ipv6[1]",
                        },
                      }),
                      _c("b-form-input", {
                        staticClass: "glue-ip",
                        attrs: { disabled: true },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.ipv6[2],
                          callback: function ($$v) {
                            _vm.$set(_vm.record.ipv6, 2, $$v)
                          },
                          expression: "record.ipv6[2]",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }