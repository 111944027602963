var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("domain-record-list", {
    attrs: {
      id: "domain-ns-records",
      title: "NS Records",
      subtitle: "(NS)",
      type: "NS",
      records: _vm.nsRecords,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }