<template>
  <domain-record-list
    id="domain-srv-records"
    title="SRV Records"
    subtitle="(SRV)"
    type="SRV"
    :records="srvRecords"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import DomainRecordList from './DomainRecordList'

export default {
  name: 'DomainSrvRecords',
  components: {
    DomainRecordList,
  },
  computed: {
    ...mapGetters('domains', [
      'srvRecords',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>
