var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ct-empty-view-component" },
    [
      _vm.icon
        ? _c("fa-icon", { attrs: { icon: _vm.icon, size: _vm.size } })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }