var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("domain-record-list", {
    attrs: {
      id: "domain-txt-records",
      title: "TXT Records",
      subtitle: "(TXT)",
      type: "TXT",
      records: _vm.txtRecords,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }