<template>
  <b-modal
    :id="modalId"
    :ref="modalId"
    title="Domain Addon Service Checkout"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="lg"
  >
    <div class="checkout-cart-container">
      <div>
        <checkout-cart
          class="checkout-cart"
          :show-tos-url="true"
          :verify-order-checkout="false"
          :verify-order-checkout-button-overrides="true"
          @submit="$emit('setup-addon-service')"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import CheckoutCart from '@/components/shared/CheckoutCart.vue'

export default {
  name: 'DomainAddonCheckoutModal',
  components: { CheckoutCart },
  props: {
    domainAddonServiceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    modalId() {
      return `domain-addon-${this.domainAddonServiceType}-checkout-modal`
    },
  },
}
</script>

<style lang="scss" scoped>
.checkout-cart-container {
  border-radius: 4px;

  .card {
    padding-top: 0 !important;
    margin: 0 !important;
  }
  .checkout-intro-container {
    padding-bottom: 2.0em;
    padding-top: 0 !important;
    margin: 0 !important;
  }
}
</style>
