var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("domain-record-list", {
    attrs: {
      id: "domain-glue-records",
      title: "Glue Records",
      type: "glue_record",
      records: _vm.glueRecords,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }