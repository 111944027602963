<template>
  <domain-record-list
    id="domain-caa-records"
    title="CAA Records"
    subtitle="(CAA)"
    type="CAA"
    :records="caaRecords"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import DomainRecordList from './DomainRecordList'

export default {
  name: 'DomainCaaRecords',
  components: {
    DomainRecordList,
  },
  computed: {
    ...mapGetters('domains', [
      'caaRecords',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>
