var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "domain-swap-configuration-container" },
    [
      _vm.swappingDomain
        ? _c("ct-centered-spinner", { staticClass: "loading-spinner mb-5" }, [
            _c("p", { staticClass: "loading-text" }, [
              _vm._v(
                "\n      Swapping your domain, this can take a few minutes...\n    "
              ),
            ]),
            _c("p", [
              _c("em", [
                _vm._v(
                  "\n        Please do not navigate away or refresh the page.\n      "
                ),
              ]),
            ]),
          ])
        : _vm.domainSwapped
        ? _c("div", [
            _c("p", [
              _vm._v("\n      Your domain\n      "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.domain.domain_name) + "\n      "
                ),
              ]),
              _vm._v("\n      has been successfully swapped to\n      "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.selectedDomain.toUpperCase()) +
                    "\n      "
                ),
              ]),
              _vm._v("\n      .\n    "),
            ]),
            _c("p", [
              _vm._v("\n      You may now exit out of this modal\n    "),
            ]),
          ])
        : _c("choose-domain", {
            attrs: {
              "domain-admin-swap": true,
              domain: _vm.domain,
              bus: _vm.bus,
              "show-name-change": false,
            },
          }),
      !_vm.domainSwapped
        ? _c(
            "div",
            { staticClass: "buttons mt-3 mb-0 d-flex flex-column flex-end" },
            [
              _c(
                "div",
                { staticClass: "slide-viewer-button-row mt-3 mb-0" },
                [
                  _c("primary-button", {
                    attrs: {
                      "button-text": "Swap Domain",
                      disabled: _vm.swappingDomain,
                    },
                    on: {
                      onclick: function ($event) {
                        return _vm.performDomainSwap()
                      },
                    },
                  }),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "default",
                        disabled: _vm.swappingDomain,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cancel")
                        },
                      },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            { staticClass: "buttons mt-3 mb-0 d-flex flex-column flex-end" },
            [
              _c(
                "div",
                { staticClass: "slide-viewer-button-row mt-3 mb-0" },
                [
                  _c("primary-button", {
                    attrs: { "button-text": "Close" },
                    on: {
                      onclick: function ($event) {
                        return _vm.$emit("hide")
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }