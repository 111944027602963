<template>
  <domain-record-list
    id="domain-glue-records"
    title="Glue Records"
    type="glue_record"
    :records="glueRecords"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import DomainRecordList from './DomainRecordList'
import { IPV6_REGEX, IPV4_REGEX } from '@/store/modules/domains.store';

export default {
  name: 'DomainGlueRecords',
  components: {
    DomainRecordList,
  },
  computed: {
    ...mapGetters('domains', [
      'nsRecords',
    ]),
    glueRecords() {
      const glues = []
      this.nsRecords.forEach((record) => {
        const data = record.data
        let index = glues.findIndex(g => g.data === data)

        if (index === -1) {
          glues.push({ data: record.data, 'ipv4': [], 'ipv6': [] })
        }

        index = glues.findIndex(g => g.data === data)

        if (index > -1) {
          if (record.name.match(IPV4_REGEX)) {
            glues[index]['ipv4'].push(record.name)
          } else if (record.name.match(IPV6_REGEX)) {
            glues[index]['ipv6'].push(record.name)
          }
        }
      })

      return glues
    },
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
