var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("domain-record-list", {
        attrs: {
          id: "domain-nameservers",
          title: "Nameservers",
          type: "nameserver",
          "add-button-text": "Add Nameserver",
          records: _vm.nameservers.map((item, index) => {
            return { key: index, value: item, type: "nameserver" }
          }),
          "editing-nameservers": _vm.editingNameservers,
        },
        on: { setEditingNameservers: _vm.setEditingNameservers },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }