<template>
  <div class="domain-admin-hosting">
    <!-- Heading ---------------------------------------------------------------------------------->
    <h4>
      <span>Domain Swapping<span v-if="subtitle" class="subtitle"> {{ subtitle }}</span></span>
    </h4>

    <p v-if="!hasUnredeemedDomainSwapVoucher">
      <strong>Domain Swapping unavailable.</strong>
      <br>
      This company doesn't have an unredeemed Business Domain Swap voucher.
      <br>
      Please issue one and return to this page to swap domain.
    </p>

    <div>
      <b-button
        variant="primary"
        :disabled="swappingDomain || !hasUnredeemedDomainSwapVoucher"
        @click="showSwapDomain"
      >
        Swap Domain
      </b-button>
    </div>

    <domain-swap-modal
      @hide="hideSwapDomainSuccess"
      @cancel="hideSwapDomainCancel"
    />
  </div>
</template>

<script>

import { makeToastMixin } from '@/mixins/makeToastMixin'
import DomainSwapModal from '@/components/Domains/DomainSwapModal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DomainAdminSwap',
  components: {
    DomainSwapModal,
  },
  mixins: [makeToastMixin],
  props: {
    subtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      swappingDomain: false,
    }
  },
  computed: {
    ...mapGetters('vouchers', ['unRedeemedVoucherByProductCategory']),
    ...mapGetters('domains', ['domain']),
    hasUnredeemedDomainSwapVoucher() {
      return !!this.unRedeemedVoucherByProductCategory('business-domain-swap')
    },
  },
  async mounted () {
    await this.fetchCompanyVouchers(this.domain.company_id)
  },
  methods: {
    ...mapActions('vouchers', ['fetchCompanyVouchers']),
    showSwapDomain() {
      this.swappingDomain = true
      this.$bvModal.show('domain-swap-modal')
    },
    hideSwapDomainSuccess() {
      this.swappingDomain = false
      this.$bvModal.hide('domain-swap-modal')
      this.goBack()
    },
    hideSwapDomainCancel() {
      this.swappingDomain = false
      this.$bvModal.hide('domain-swap-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
  h4 {
    padding: unset;
    margin: unset;
    height: 5.5rem;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .subtitle {
      font-weight: 200;
    }
  }
</style>
