var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "domain-whois-admin" } },
    [_c("whois-contact-info", { attrs: { "whois-key": "admin" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }