<template>
  <b-modal
    id="setup-email-client-modal"
    ref="setup-email-client-modal"
    title="Setup Email Client"
    centered
    size="lg"
  >
    <client-setup />
    <settings :domain="domainName" />
    <template v-slot:modal-footer>
      <b-button variant="default" aria-label="Done button" @click="$emit('hide')">
        Done
      </b-button>
    </template>
  </b-modal>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'SetupEmailClientModal',
  components: {
    ClientSetup:       () => import('@/components/Services/BusinessEmail/ClientSetup'),
    Settings:          () => import('@/components/Services/BusinessEmail/Settings.vue'),
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
    domainName() {
      return this.domain?.domain_name?.toLowerCase() || ''
    },
  },
}
</script>
