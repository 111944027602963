<template>
  <b-modal
    id="reactivate-service-window"
    ref="reactivateServiceWindow"
    title="Confirm Service Reactivation"
    size="xl"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    @hidden="resetModal"
  >
    <slot v-if="loaded" name="body">
      <div>
        <p>Please select the payment method you would like to use to make your payment today, {{ dateToday }}.</p>
        <payment-method-options />
        <p><i>If you would like to use a different payment method, please add the payment method to your Payment Methods under Account Settings first.</i></p>
      </div>
      <p>I agree to reactivate my {{ service.product.name }} service for the domain <b>{{ domain.domain_name }}</b> for ${{ currentProduct.price }} per {{ pricePeriod }}.</p>
      <b-row v-if="tosUrl">
        <b-col>
          <b-form-group>
            <span class="mt-4 mb-2">
              By clicking 'Reactivate', I accept the <a :href="tosUrl" target="_blank">Terms and Conditions</a>
            </span>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button v-if="!loading" class="reactivate-button" variant="primary" :disabled="!submitButtonEnabled" @click="onSubmit()">
        Reactivate
      </b-button>
      <b-button v-if="!loading" class="reactivate-button ml-md-1" variant="outline-danger" @click="resetModal()">
        Cancel
      </b-button>

      <ct-centered-spinner v-if="loading" class="loading-spinner mb-lg-5">
        <p class="centered-spinner-text">
          Reactivating {{ service.product['name'] }} service...
        </p>
        <p>
          <em>
            Please do not navigate away or refresh the page.
          </em>
        </p>
      </ct-centered-spinner>
    </slot>
    <div v-else class="d-flex flex-row justify-content-center">
      <ct-centered-spinner label="Loading..." />
    </div>
  </b-modal>
</template>

<script>
import http from '@/http'
import { mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions'

export default {
  name: 'ReactivateDomainServiceConfirmWindow',
  components: { PaymentMethodOptions, CtCenteredSpinner },
  props: {
    service: {
      type: Object,
      required: true,
    },
    isDeleting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateToday: new Date().toLocaleDateString(),
      loading: false,
      loaded: false,
      tosUrl: null,
    }
  },
  computed: {
    ...mapGetters('domains', ['domain']),
    ...mapGetters('products', ['currentProduct']),
    ...mapGetters('checkout', ['agreementAccepted', 'selectedPaymentMethod']),
    submitButtonEnabled() {
      return this.selectedPaymentMethod && Object.keys(this.selectedPaymentMethod).length !== 0 && this.agreementAccepted
    },
    pricePeriod() {
      return this.currentProduct.duration === 1 ? 'month' : 'year'
    },
  },
  async mounted () {
    this.loaded = true

    this.$nextTick(() => {
      this.$refs.reactivateServiceWindow.show()
    })
  },
  methods: {
    successToast(){
      this.$root.$bvToast.toast('Service Reactivated!', {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 3000,
      })
    },
    failureToast(){
      this.$root.$bvToast.toast('Something went wrong, please try again later!', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    },
    async onSubmit() {
      this.loading = true
      try{
        this.$emit('changeInProcess')
        await http.put(`client/domains/${this.domain.id}/addons/${this.service.id}`, {
          payable_id: this.selectedPaymentMethod.id,
        })
        this.$emit('hideConfirmWindow')
        this.service.cancellation_status = null
        this.service.suspend_date = null
        this.service.delete_date = null
        this.service.status = 'active'

        this.successToast()
        this.loading = false
      } catch (error) {
        this.failureToast()
        this.loading = false
      }
    },
    resetModal(){
      this.$emit('hideConfirmWindow')
    },
  },
}
</script>

<style lang="scss" scoped>
  .reactivate-button {
    border-radius: 4px !important;
    padding: 0.5em 1.0em 0.5em 0.625em !important;
    text-align: center;
  }
  .centered-spinner-text {
    width: 25rem;
  }
  ::v-deep .modal-body {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
</style>
