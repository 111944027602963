var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "domain-admin-hosting" },
    [
      _c("h4", [
        _c("span", [
          _vm._v("Domain Swapping"),
          _vm.subtitle
            ? _c("span", { staticClass: "subtitle" }, [
                _vm._v(" " + _vm._s(_vm.subtitle)),
              ])
            : _vm._e(),
        ]),
      ]),
      !_vm.hasUnredeemedDomainSwapVoucher
        ? _c("p", [
            _c("strong", [_vm._v("Domain Swapping unavailable.")]),
            _c("br"),
            _vm._v(
              "\n    This company doesn't have an unredeemed Business Domain Swap voucher.\n    "
            ),
            _c("br"),
            _vm._v(
              "\n    Please issue one and return to this page to swap domain.\n  "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              attrs: {
                variant: "primary",
                disabled:
                  _vm.swappingDomain || !_vm.hasUnredeemedDomainSwapVoucher,
              },
              on: { click: _vm.showSwapDomain },
            },
            [_vm._v("\n      Swap Domain\n    ")]
          ),
        ],
        1
      ),
      _c("domain-swap-modal", {
        on: {
          hide: _vm.hideSwapDomainSuccess,
          cancel: _vm.hideSwapDomainCancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }