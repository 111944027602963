var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("domain-record-list", {
    attrs: {
      id: "domain-mx-records",
      title: "Email Service",
      subtitle: "(MX)",
      type: "MX",
      records: _vm.mxRecords,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }