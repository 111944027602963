<template>
  <domain-record-list
    id="domain-subdomains"
    title="Subdomains"
    subtitle="(CNAME)"
    type="CNAME"
    :records="subdomains"
  />
</template>

<script>
import DomainRecordList from './DomainRecordList'
import { mapGetters } from 'vuex'

export default {
  name: 'DomainSubdomains',
  components: {
    DomainRecordList,
  },
  computed: {
    ...mapGetters('domains', [
      'subdomains',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>
