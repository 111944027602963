var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "domain-website-hosting" } },
    [
      _c(
        "h4",
        [
          _c("span", [
            _vm._v("Website Hosting"),
            _vm.subtitle
              ? _c("span", { staticClass: "subtitle" }, [
                  _vm._v(" " + _vm._s(_vm.subtitle)),
                ])
              : _vm._e(),
          ]),
          _vm.serviceIsExpiring
            ? _c(
                "b-btn",
                {
                  staticClass: "float-right",
                  attrs: { variant: "primary" },
                  on: { click: _vm.openConfirmWindow },
                },
                [_vm._v("\n      Reactivate Business Website\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.serviceIsExpiring
        ? _c("domain-service-pending-removal", {
            attrs: { service: _vm.service },
          })
        : _vm._e(),
      _c("br"),
      _c(
        "h5",
        [
          _c("strong", { staticClass: "mr-1" }, [_vm._v("Wordpress Status:")]),
          _c("br"),
          _vm.wpStatus.status !== 1
            ? _c(
                "span",
                [
                  _vm._v("\n      Status Code:\n      "),
                  _c("fa-icon", {
                    style: { color: _vm.wpStatus.color },
                    attrs: { icon: _vm.wpStatus.icon },
                  }),
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "client_status_code",
                        placement: "top",
                        offset: "60",
                        width: "25rem",
                      },
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.wpStatus.message) },
                      }),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "status-code",
                      attrs: { id: "client_status_code" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.wpStatus.status) +
                          "\n        "
                      ),
                      _c("fa-icon", { attrs: { icon: "external-link-alt" } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "span",
                [
                  _c(
                    "ct-centered-spinner",
                    { staticClass: "loading-spinner mb-lg-5" },
                    [
                      _c("p", { staticClass: "centered-spinner-text" }, [
                        _vm._v(
                          "\n          Fetching Domain Hosting Status...\n        "
                        ),
                      ]),
                      _c("p", [
                        _c("em", [
                          _vm._v(
                            "\n            This can take a minute.\n          "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
          _c("br"),
          _vm.wpStatus.status !== 1
            ? _c(
                "b-btn",
                {
                  staticClass: "float-left mt-1 mb-1",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.refreshWebsiteView()
                    },
                  },
                },
                [
                  _vm._v("\n      Refresh\n      "),
                  _c("b-icon-arrow-clockwise"),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.wpStatus.status !== 1
        ? _c("iframe", {
            attrs: {
              id: "client-website-view",
              src: _vm.domainUrl,
              width: "100%",
              height: "450rem",
            },
          })
        : _vm._e(),
      _vm.showConfirmWindow
        ? _c("reactivate-domain-service-confirm-window", {
            attrs: {
              service: _vm.service,
              "is-deleting": _vm.serviceIsDeleting,
            },
            on: {
              hideConfirmWindow: _vm.hideConfirmWindow,
              changeInProcess: _vm.changeInProcess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }