var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      attrs: { id: "add-domain-email" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [_vm._v("\n    Create New Email Address\n  ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "add-domain-email-form-group" },
        [
          _c(
            "b-form",
            { staticClass: "w-100" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Email Address",
                            "label-for": "username",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { append: "@" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "username",
                                  disabled: _vm.loading,
                                },
                                model: {
                                  value: _vm.username,
                                  callback: function ($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "domain-name px-0 flex-grow-1",
                      attrs: { cols: "4" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.domain.domain_name) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "password",
                          rules: "required|domainEmailPasswordValidator",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ validate, errors }) {
                              return [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Password",
                                      "label-for": "password",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "password",
                                            disabled: _vm.loading,
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.validatePassword(
                                                validate
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.password,
                                            callback: function ($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          {
                                            staticClass: "password-visibility",
                                          },
                                          [
                                            _c("fa-icon", {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                icon: _vm.showPassword
                                                  ? "eye"
                                                  : "eye-slash",
                                              },
                                              on: {
                                                click:
                                                  _vm.togglePasswordVisibility,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.password.length && errors.length
                                          ? _c(
                                              "b-card",
                                              { staticClass: "small popdown" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      errors.length > 0
                                                        ? errors[0]
                                                        : ""
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-actions ml-3" },
            [
              !_vm.loading
                ? [
                    _c(
                      "b-btn",
                      {
                        attrs: {
                          variant: "primary",
                          disabled:
                            !_vm.isPasswordValid || _vm.username.length < 1,
                        },
                        on: { click: _vm.save },
                      },
                      [_vm._v("\n          Save\n        ")]
                    ),
                    _c(
                      "b-btn",
                      {
                        attrs: { variant: "outline-danger" },
                        on: { click: _vm.hide },
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    ),
                  ]
                : _c("ct-centered-spinner"),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }