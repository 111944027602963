<template>
  <div id="domain-whois-admin">
    <whois-contact-info whois-key="admin"></whois-contact-info>
  </div>
</template>

<script>
import WhoisContactInfo from './WhoisContactInfo.vue'

export default {
  name: 'DomainWhoisAdmin',
  components: {
    WhoisContactInfo,
  },
}
</script>

<style lang="scss" scoped>
</style>
