<template>
  <domain-record-list
    id="domain-mx-records"
    title="Email Service"
    subtitle="(MX)"
    type="MX"
    :records="mxRecords"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import DomainRecordList from './DomainRecordList'

export default {
  name: 'DomainMxRecords',
  components: {
    DomainRecordList,
  },
  computed: {
    ...mapGetters('domains', [
      'mxRecords',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>
