<template>
  <div v-if="action === 'deleted permanently'">
    <strong>
      Your {{ serviceName }} will be {{ action }} {{ untilTheEndDate }}
    </strong>
    <p class="alert-description">
      There's still time to save your {{ serviceName }} data. For assistance and to go over your options for saving or transferring data, please give us a call at <span v-phone-number="phone()" /> (M-F 8AM-4:30PM PST).
    </p>
  </div>
  <div v-else-if="action === 'suspended'">
    <strong>
      {{ serviceName }} Cancellation - Data Removal
    </strong>
    <p class="alert-description">
      Your {{ serviceName }} data will no longer be accessible by our Web Support team after {{ formatDateString(deleteDateString) }}. If you are considering revisiting this service or need a copy of your data, please give us a call at <span v-phone-number="phone()" /> (M-F 8AM-4:30PM PST).
    </p>
  </div>
</template>

<script>
import { formatDateString, returnDateForBrowserTimezone } from '@/common/modules/formatters'
import { phoneNumber } from '@/directives/directives'
export default {
  name: "DomainServiceRemovalTimer",
  directives: { phoneNumber },
  props: {
    action: String,
    serviceName: String,
    actionDateString: String,
    deleteDateString: String,
    actionTimeFrame: Number,
  },
  data(){
    return {
      untilTheEndDate: '',
      distance: '',
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
      website: this.$store.getters['website/website'],
    }
  },
  mounted(){
    setInterval(() => {
      this.timer()
    }, 1000)
  },
  methods: {
    formatDateString,
    returnDateForBrowserTimezone: returnDateForBrowserTimezone,
    timer() {
      this.setDistance()
      this.setTimeIntervals()
      this.untilTheEndDate = this.days > 0 ? `in ${this.days}d ${this.hours}h` : 'soon'
    },
    setTimeIntervals() {
      this.days = Math.floor(this.distance / (1000 * Math.pow(60, 2) * 24))
      this.hours = Math.floor((this.distance % (1000 * Math.pow(60, 2) * 24)) / (1000 * Math.pow(60, 2)))
      this.minutes = Math.floor((this.distance % (1000 * Math.pow(60, 2))) / (1000 * 60))
      this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000)
    },
    setDistance() {
      const endDate = this.returnDateForBrowserTimezone(this.actionDateString).ts
      const now = new Date().getTime()
      this.distance = endDate - now
    },
    phone() {
      if (!this.website || !Array.isArray(this.website.phone_numbers)) {
        return null
      }

      return (this.website.phone_numbers.find((a) => a.kind === 'billing') || {}).phone_number
    },
  },
}
</script>

<style lang="scss" scoped>
  .alert-description {
    font-weight: 400;
    font-size: 0.875rem;
    margin-bottom: 0;
    padding-right: 1rem;
  }
</style>
