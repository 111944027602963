var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "domain-swap-modal",
      attrs: {
        id: "domain-swap-modal",
        title: "Domain Swapping",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        centered: "",
        "hide-header-close": "",
        "hide-footer": "",
        size: "lg",
      },
    },
    [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.modalText) } }),
      _c("hr"),
      _c("domain-swap-configuration", {
        on: {
          cancel: function ($event) {
            return _vm.$emit("cancel")
          },
          hide: function ($event) {
            return _vm.$emit("hide")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }