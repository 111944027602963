<template>
  <b-modal
    :id="modalId"
    :ref="modalId"
    title="Domain Addon Special Offer"
    no-close-on-backdrop
    centered
    hide-footer
    size="lg"
  >
    <div class="additional-offer-container">
      <i>
        Add {{ getAddonProductsString() }} to unlock 20% off all <strong>Online Presence</strong> services!
      </i>

      <br>

      <div v-if="!isDomainSsl && !!domainAddonOtherServiceProduct && showSslOffer">
        <b-radio-group v-model="packageSelected" class="mb-3">
          <b-radio :value="true" @change="activateAll()">
            Yes please!
          </b-radio>
          <b-radio :value="false" @change="deactivateOther()">
            No thanks.
          </b-radio>
        </b-radio-group>

        <p>
          Or, you can select any additional service you would like to add.
        </p>
      </div>

      <div class="mb-3">
        <b-checkbox v-if="!!domainAddonOtherServiceProduct" v-model="otherServiceChecked">
          {{ domainAddonOtherServiceProduct.name }}
        </b-checkbox>
        <b-checkbox v-if="!isDomainSsl && showSslOffer" v-model="sslServiceChecked">
          {{ domainAddonSslServiceProduct.name }}
        </b-checkbox>
        <b-alert v-if="!sslServiceChecked" show variant="danger" class="mb-3">
          <p>
            <strong class="mb-1">WARNING: {{ domainAddonSslServiceProduct.name }} Service Disabled!</strong>
          </p>
          <p>
            SSL service is <strong>highly</strong> recommended for <strong>Business Website Hosting</strong> or <strong>Business Email</strong> service.
            Without it, your website will be insecure and your user's browsers will mark the website as untrustworthy. Additionally, your emails won't be secure. Please be aware of this before continuing, you can add SSL later if you choose not to right now.
          </p>
        </b-alert>
      </div>

      <b-btn
        v-if="!loadingCheckout"
        variant="primary"
        @click="addAdditionalProductsToCartAndContinue()"
      >
        Continue to Checkout
      </b-btn>

      <ct-centered-spinner v-else class="loading-spinner mb-5" />
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'

export default {
  name: 'DomainAddonAdditionalOffersModal',
  components: { CtCenteredSpinner },
  props: {
    domainAddonServiceType: {
      type: String,
      required: true,
    },
    domainAddonServiceProduct: {
      type: Object,
      required: true,
    },
    domainAddonOtherServiceProduct: {
      type: Object,
    },
    domainAddonSslServiceProduct: {
      type: Object,
      required: true,
    },
    showSslOffer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      packageSelected: false,
      otherServiceChecked: false,
      sslServiceChecked: true,
      loadingCheckout: false,
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'productIdInCart',
    ]),
    modalId() {
      return `domain-addon-${this.domainAddonServiceType}-additional-offers-modal`
    },
    allServicesActive() {
      return this.otherServiceChecked && this.sslServiceChecked
    },
    isDomainSsl() {
      return this.domainAddonServiceType === 'domain-ssl'
    },
  },
  watch: {
    allServicesActive(newVal) {
      this.packageSelected = newVal === true
    },
  },
  methods: {
    ...mapActions('checkout', [
      'addToCart',
    ]),
    async addAdditionalProductsToCartAndContinue() {
      this.loadingCheckout = true

      let product_ids = [
        this.domainAddonServiceProduct.id,
        this.otherServiceChecked ? this.domainAddonOtherServiceProduct.id : null,
        this.sslServiceChecked ? this.domainAddonSslServiceProduct.id : null,
      ].filter((x) => x !== null)

      //Make sure the product isn't already in the cart before we add it.
      if (!!this.domainAddonOtherServiceProduct && !this.productIdInCart(this.domainAddonOtherServiceProduct.id) && this.otherServiceChecked) {
        await this.addToCart(this.domainAddonOtherServiceProduct)
      }

      if (!this.productIdInCart(this.domainAddonSslServiceProduct.id) && this.sslServiceChecked) {
        await this.addToCart(this.domainAddonSslServiceProduct)
      }

      this.loadingCheckout = false

      this.$emit('continue-to-checkout', product_ids)
      this.$bvModal.hide(this.modalId)
    },
    activateAll() {
      this.otherServiceChecked = true
      this.sslServiceChecked = true
    },
    deactivateOther() {
      this.otherServiceChecked = false
    },
    getAddonProductsString(){
      const noOtherProductButShowSsl = !this.domainAddonOtherServiceProduct && this.showSslOffer
      const hasOtherProductButDontShowSsl = this.domainAddonOtherServiceProduct && !this.showSslOffer

      if (!this.isDomainSsl && !!this.domainAddonOtherServiceProduct && this.showSslOffer) {
        return this.domainAddonOtherServiceProduct.name + " and " + this.domainAddonSslServiceProduct.name
      } else if (this.isDomainSsl || (!this.isDomainSsl && noOtherProductButShowSsl)) {
        return this.domainAddonSslServiceProduct.name
      } else if (!this.isDomainSsl && hasOtherProductButDontShowSsl) {
        return this.domainAddonOtherServiceProduct.name
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.additional-offer-container {
  border-radius: 4px;

  .card {
    padding-top: 0 !important;
    margin: 0 !important;
  }
  .checkout-intro-container {
    padding-bottom: 2.0em;
    padding-top: 0 !important;
    margin: 0 !important;
  }
}
</style>
