var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "email-delete-modal",
      attrs: {
        id: "email-delete-modal",
        title: "Delete Email Account?",
        centered: "",
        "hide-header-close": "",
        size: "md",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "default" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("hide")
                    },
                  },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("delete")
                    },
                  },
                },
                [_vm._v("\n      Delete Email Account\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c("div", { domProps: { innerHTML: _vm._s(_vm.modalText) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }