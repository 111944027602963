var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "domain-record-list" },
    [
      _c(
        "h4",
        [
          _c("span", [
            _vm._v(_vm._s(_vm.title)),
            _vm.subtitle
              ? _c("span", { staticClass: "subtitle" }, [
                  _vm._v(" " + _vm._s(_vm.subtitle)),
                ])
              : _vm._e(),
          ]),
          _vm.showAddButton
            ? _c(
                "b-btn",
                {
                  staticStyle: { float: "right" },
                  attrs: { variant: "primary", disabled: !_vm.activeDomain },
                  on: { click: _vm.showAddRecord },
                },
                [_vm._v("\n      Add New Record\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("add-domain-record", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.addingRecord,
            expression: "addingRecord",
          },
        ],
        attrs: { type: _vm.type },
        on: { hide: _vm.hideAddRecord, cancel: _vm.hideAddRecord },
      }),
      _vm.records.length > 0
        ? _vm._l(_vm.records, function (record) {
            return _c("domain-record", {
              key: record.id,
              attrs: {
                type: _vm.type,
                record: record,
                "enable-spinner": true,
                "editing-nameservers": _vm.editingNameservers,
              },
              on: {
                setEditingNameservers: function ($event) {
                  return _vm.$emit("setEditingNameservers", $event)
                },
              },
            })
          })
        : _c("ct-empty-view", { staticClass: "mt-5" }, [
            _vm._v("\n    You have no records of this type.\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }