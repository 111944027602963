var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "setup-email-client-modal",
      attrs: {
        id: "setup-email-client-modal",
        title: "Setup Email Client",
        centered: "",
        size: "lg",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "default", "aria-label": "Done button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("hide")
                    },
                  },
                },
                [_vm._v("\n      Done\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c("client-setup"), _c("settings", { attrs: { domain: _vm.domainName } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }