var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("domain-record-list", {
    attrs: {
      id: "domain-subdomains",
      title: "Subdomains",
      subtitle: "(CNAME)",
      type: "CNAME",
      records: _vm.subdomains,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }